.psv-marker {
  transition: opacity 0.5s ease;
  transform: scale(1);
  opacity: 1;
}

.psv-marker.hide {
  opacity: 0;
  pointer-events: none;
}

.psv-marker:hover {
  transform: scale(1.2);
}

.psv-marker .marker {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid #fff;
  border-radius: 50%;
  transition: transform 0.5s ease, background 0.5s ease;
  transform: scale(1);
}

.psv-marker .marker:hover {
  cursor: pointer;
  transform: scale(1.3);
  background-color: rgba(0, 0, 0, 1);
}

.psv-marker .doorway-forward {
  background-image: url('./icons/button-proceed-normal.svg');
}

.psv-marker .doorway-back {
  background-image: url('./icons/button-return-normal.svg');
}

.psv-marker .go-to-present {
  background-image: url('./icons/button-time-forward.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.psv-marker .go-to-past {
  background-image: url('./icons/button-time-back.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.psv-marker .info {
  background-image: url('./icons/button-info-normal.svg');
  background-size: 60px;
  background-position: center;
}
.psv-marker .info.opened::after {
  content: '';
  position: absolute;
  bottom: -15px;
  right: -20px;
  width: 40px;
  height: 40px;
  background-image: url('./icons/tick.svg');
  background-position: center;
  background-size: cover;
}

.psv-marker.closest::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgb(0, 0, 0);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(100, 100, 100, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 30px rgba(100, 100, 100, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(100, 100, 100, 0);
  }
}
