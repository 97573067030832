@font-face {
  font-family: 'Argesta Hairline';
  src: url('./ArgestaHairline-Regular.woff2') format('woff2'),
    url('./ArgestaHairline-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Argesta Headline';
  src: url('./ArgestaHeadline-RegularItalic.woff2') format('woff2'),
    url('./ArgestaHeadline-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Argesta Hairline';
  src: url('./ArgestaHairline-RegularItalic.woff2') format('woff2'),
    url('./ArgestaHairline-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Argesta Text';
  src: url('./ArgestaText-Regular.woff2') format('woff2'),
    url('./ArgestaText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Argesta Text';
  src: url('./ArgestaText-Bold.woff2') format('woff2'),
    url('./ArgestaText-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Argesta Text';
  src: url('./ArgestaText-RegularItalic.woff2') format('woff2'),
    url('./ArgestaText-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Argesta Display';
  src: url('./ArgestaDisplay-RegularItalic.woff2') format('woff2'),
    url('./ArgestaDisplay-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Argesta Display';
  src: url('./ArgestaDisplay-Regular.woff2') format('woff2'),
    url('./ArgestaDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Argesta Headline';
  src: url('./ArgestaHeadline-Regular.woff2') format('woff2'),
    url('./ArgestaHeadline-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
